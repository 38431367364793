.inquiry{
    padding-top: 30px;
    padding-left: 10%;
    padding-right: 10%;
}

.inquiry a {
    color: inherit;
}

@media screen and (max-width: 800px){

}