.techmastrateg {
    max-width: 1200px;
    padding-top: 30px;
    padding-left: 5%;
    padding-right: 5%;
}

.techmastrateg > .row{
    border-bottom: 1px solid #d9d9da;
    padding-bottom: 50px;
    margin-bottom: 50px;
}

@media screen and (max-width: 800px) {

}