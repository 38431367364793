.main {
    overflow: hidden;
}

.banerLeft {
    width: 105%
}

.banerRight {
    width: 105%
}

.nbcr{
    border-top: 1px solid #d9d9da;
}

@media screen and (max-width: 576px){
    .nbcr img{
        max-width: 200px;
    }
}