.flags{
    max-width: 160px;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
}

.flags.hidden{
    margin-top: -100%;
}

@media screen and (min-width: 1800px){
    .design-lab-1{
        height: 70px !important;
        margin: auto;
        padding: 5px 0;
        display: inline-block;
        vertical-align: middle;
        line-height: 29px;
    }
    .design-lab-2 {
        height: 100px !important;
        margin: auto;
        padding: 20px 0;
        display: inline-block;
        vertical-align: middle;
        line-height: 29px;
    }
    .design-lab-3 {
        height: 120px !important;
        margin: auto;
        padding: 15px 0;
        display: inline-block;
        vertical-align: middle;
        line-height: 29px;
    }
    .design-lab-4 {
        height: 100px !important;
        margin: auto;
        padding: 20px 0;
        display: inline-block;
        vertical-align: middle;
        line-height: 29px;
    }
    .design-lab-1-container {
        margin: 10px 0;
        display: inline-block;
    }
    .design-lab-2-container {
        margin: 10px 0;
        display: inline-block;
    }
    .design-lab-3-container {
        margin: 10px 0;
        display: inline-block;
    }
    .design-lab-4-container {
        margin: 10px 0;
        display: inline-block;
    }
}

@media screen and (max-width: 400px){
    .we-deliver {
        margin-top: 70px;
    }
}

@media screen and (max-width: 1800px) and (min-width: 1440px) {
    .design-lab-1{
        height: 65px !important;
        margin: auto;
        padding: 5px 0;
        display: inline-block;
        vertical-align: middle;
        line-height: 29px;
    }
    .design-lab-2 {
        height: 65px !important;
        margin: auto;
        padding: 5px 0;
        display: inline-block;
        vertical-align: middle;
        line-height: 29px;
    }
    .design-lab-3 {
        height: 125px !important;
        margin: auto;
        padding: 5px 0;
        display: inline-block;
        vertical-align: middle;
        line-height: 29px;
    }
    .design-lab-4 {
        height: 95px !important;
        margin: auto;
        padding: 5px 0;
        display: inline-block;
        vertical-align: middle;
        line-height: 29px;
    }
    .design-lab-1-container {
        margin: 5px 0;
        display: inline-block !important;
        line-height: 65px;
    }
    .design-lab-2-container {
        margin: 5px 0;
        display: inline-block !important;
        line-height: 55px;
    }
    .design-lab-3-container {
        margin: 5px 0;
        display: inline-block !important;
        line-height: 105px;
    }
    .design-lab-4-container {
        margin: 5px 0;
        display: inline-block !important;
        line-height: 80px;
    }
}

@media screen and (max-width: 1439px) and (min-width: 1201px) {
    .design-lab-1{
        height: 65px !important;
        margin: auto;
        padding: 5px 0;
        display: inline-block;
        vertical-align: middle;
        line-height: 29px;
        font-size: 12px !important;
    }
    .design-lab-2 {
        height: 65px !important;
        margin: auto;
        padding: 5px 0;
        display: inline-block;
        vertical-align: middle;
        line-height: 29px;
        font-size: 12px !important;
    }
    .design-lab-3 {
        height: 125px !important;
        margin: auto;
        padding: 5px 0;
        display: inline-block;
        vertical-align: middle;
        line-height: 29px;
        font-size: 12px !important;
    }
    .design-lab-4 {
        height: 95px !important;
        margin: auto;
        padding: 5px 0;
        display: inline-block;
        vertical-align: middle;
        line-height: 29px;
        font-size: 12px !important;
    }
    .design-lab-1-container {
        margin: 5px 0;
        display: inline-block !important;
        line-height: 65px;
    }
    .design-lab-2-container {
        margin: 5px 0;
        display: inline-block !important;
        line-height: 55px;
    }
    .design-lab-3-container {
        margin: 5px 0;
        display: inline-block !important;
        line-height: 105px;
    }
    .design-lab-4-container {
        margin: 5px 0;
        display: inline-block !important;
        line-height: 80px;
    }
}

@media screen and (max-width: 1200px) {
    .design-lab-1-container {
        margin: 5px 0;
    }
    .design-lab-2-container {
        margin: 5px 0;
    }
    .design-lab-3-container {
        margin: 5px 0;
    }
    .design-lab-4-container {
        margin: 5px 0;
    }
    .design-lab-1 {
        padding: 5px 0;
    }
    .design-lab-2 {
        padding: 5px 0;
    }
    .design-lab-3 {
        padding: 5px 0;
    }
    .design-lab-4 {
        padding: 5px 0;
    } 
}

@media screen and (max-width: 1200px){
    .map-countries {
        visibility: hidden;
    }
    .mtm-left {
        padding-top: 20px;
        text-align: left;
    }
    
    .mtm-right {
        padding-top: 20px;
    }

    .tt-head {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1572px) {
    .rfid-providing-first{
        height: 120px;
        padding: 25px 5px !important;
        font-size: 15px;
       /* vertical-align: middle;
        display: inline-block;
        line-height: 30px; */
    }
    .rfid-providing-secound{
        height: 120px;
        padding: 33px 5px !important;
        font-size: 15px;
        vertical-align: middle;
        display: inline-block;
    }
    .rfid-providing-third{
        height: 120px;
        padding: 15px 5px !important;
        font-size: 15px;
        vertical-align: middle;
        display: inline-block;
    }
}

@media screen and (min-width: 1573px) {
    .rfid-providing-first{
        height: 120px;
        padding: 35px 5px;
        font-size: 15px;
       /* vertical-align: middle;
        display: inline-block;
        line-height: 30px; */
    }
    .rfid-providing-secound{
        height: 120px;
        padding: 35px 5px;
        font-size: 15px;
        vertical-align: middle;
        display: inline-block;
    }
    .rfid-providing-third{
        height: 120px;
        padding: 25px 5px;
        font-size: 15px;
        vertical-align: middle;
        display: inline-block;
    }
}

@media screen and (min-width: 1201px){
.mtm-left {
    padding-top: 200px;
    text-align: right;
}

.mtm-right {
    padding-top: 175px;
}
}

.partners {
    width: 80px !important;
}

.rfid-tags {
    font-size: 12px;
}
/*
.rfid-providing-first{
    height: 120px;
    padding: 35px 5px;
    font-size: 15px;
   /* vertical-align: middle;
    display: inline-block;
    line-height: 30px; 
}
/*.rfid-providing-secound{
    height: 120px;
    padding: 35px 5px;
    font-size: 15px;
    vertical-align: middle;
    display: inline-block;
}
.rfid-providing-third{
    height: 120px;
    padding: 25px 5px;
    font-size: 15px;
    vertical-align: middle;
    display: inline-block;
}
*/
.rfid-tags-group {
    display: inline;
}

.linkk{
    color: white;
    text-decoration: none;
}

.linkk:hover {
    color: #0198de;
    text-decoration: none;
}

.main-upper-text {
    color: white;
    z-index: 10;
    font-size: 50px;
    position: absolute;
    text-align: center;
    top: 32vh;
    left: 50%;
    font-weight: 200;
}

.main-bottom-text {
    color: #0398de;
    z-index: 10;
    font-size: 45px;
    position: absolute;
    text-align: center;
    top: 42%;
    left: 50%;
    font-weight: 200
}

.sec-car {
    height: 450px;
}

@media screen and (max-width: 1200px){
    .sec-car {
        height: 200px;
    }

    .main-upper-text {
        color: white;
        z-index: 10;
        font-size: 40px;
        position: absolute;
        text-align: center;
        top: 43vh;
        left: 50%;
        font-weight: 200;
    }

    .home-rev-photo{
        margin: 20px 0 30px 0;
    }
    
    .main-bottom-text {
        color: #0398de;
        z-index: 10;
        font-size: 30px;
        position: absolute;
        text-align: center;
        top: 55vh;
        left: 50%;
        font-weight: 200;
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .rfid-tags-group {
        display: grid;
        margin-top: 20px;
    }

    .rfid-tags {
        margin: 10px 0;
    }
}

@media screen and (min-width: 1819px) {
    .rfid-providing-first {
        padding: 35px 5px;
    }
}

@media screen and (max-width: 1646px) and (min-width: 1200px){
    .rfid-providing-first{
        font-size: 12px;
        padding: 25px 5px;
    }
    .rfid-providing-secound{
       font-size: 12px; 
        padding: 25px 5px;
    }
    .rfid-providing-third{
        font-size: 12px;
        padding: 25px 5px;
    }
    .rfid-tags{
        font-size: 11px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 705px) {
    .rfid-providing-first{
        padding: 45px 5px;
    }
    .rfid-providing-secound{
        padding: 45px 5px;
        margin: auto;
    }
    .rfid-providing-third{
        padding: 45px 5px;
    }
}

@media screen and (max-width: 1646px) and (min-width: 1400px){
    .rfid-providing-first{
        padding: 40px 5px;
    }
    .rfid-providing-secound{
        padding: 30px 5px;
    }
    .rfid-providing-third{
        padding: 30px 5px;
    }
}

@media screen and (max-width: 1447px) and (min-width: 1200px){
    .rfid-providing-first {
        padding: 35px 5px;
    }
}

@media screen and (max-width: 704px) and (min-width: 500px){
    .rfid-providing-first{
        padding: 47px 5px;
    }
    .rfid-providing-secound{
        padding: 35px 5px;
    }
    .rfid-providing-third{
        padding: 35px 5px;
    }
}

@media screen and (max-width: 499px) and (min-width: 401px){
    .rfid-providing-first{
        padding: 37px 5px;
    }
    .rfid-providing-secound{
        padding: 28px 5px;
    }
    .rfid-providing-third{
        padding: 37px 10px;
    }
}

@media screen and (max-width: 400px){
    .rfid-providing-first {
        padding: 40px 5px;
    }
    .rfid-providing-secound {
        padding: 28px 5px;
    }
    .rfid-providing-third {
        padding: 28px 10px;
    }
}
